.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;

  @include viewport--md {
    padding: 0 24px;
  }
}
