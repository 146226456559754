
.time-line-main {

  @include viewport--md {
    padding-top: 140px;
  }
  @include viewport--sm {
    padding-top: 150px;
  }
}

.time-line {
  width: 100%;
  min-height: 500px;

  @include viewport--xl {
    padding-left: 50px;
  }
  @include viewport--md {
    padding-left: 0;
  }
}

.time-line__wrapper {
  position: relative;
  max-width: 1390px;
  margin: 0 auto;


  @include viewport--md {
    width: 100%;
  }

  & > p {
    top: -10px;

    @include viewport--md {
      top: 18px;
      left: 24px!important;
    }
  }

}

.time-line__text-container {
  position: absolute;
  left: 0;
  top: 64px;
  width: 170px;
  height: 90px;
  overflow: hidden;

  p {
    top: -2px;
    left: 0;

    @include viewport--md {
      top: 0;
    }

    &:nth-child(2n + 2) {
      left: 77px;

      @include viewport--xl {
        left: 50px;
      }

      @include viewport--md {
        top: -40px;
        left: 21px;
      }
    }
  }

  @include viewport--xl {
    left: 0;
  }
  @include viewport--md {
    position: relative;
    left: 65px;
    top: -22px;
    width: 90px;
    height: 40px;
  }
}

.time-line__wrapper > p,
.time-line__text-container p {
  position: absolute;
  left: 26px;
  display: block;
  margin: 0;
  font-weight: 800;
  font-size: 90px;
  line-height: 70px;
  color: $color-tahiti-gold;
  letter-spacing: -0.03em;
  z-index: 20;

  @include viewport--xl {
    left: 0;
  }

  @include viewport--md {
    position: relative;
    font-size: 36px;
    line-height: 40px;
    z-index: 0;
  }
}

.time-line__button-prev,
.time-line__button-next,
.time-line__button-down {
  position: absolute;
  left: 0;
  top: 260px;
  z-index: 1;
  display: block;
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  outline: none;
  color: $color-tahiti-gold;
  transition: opacity $default-transition-settings;
  opacity: 1;

  &:hover {
    opacity: .6;
  }

  &.swiper-button-disabled {
    pointer-events: none;
    color: $color-gainsboro-2;

    @include viewport--md {
      border-color: $color-gainsboro-2;

      svg {
        color: $color-gainsboro-2;
      }
    }
  }

  @include viewport--md {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: auto;

    svg {
      min-width: 50px;
      max-width: 50px;
      color: $color-tahiti-gold;
    }
  }
}

.time-line__button-next {
  left: 55px;

  @include viewport--md {
    left: 0;
  }
}

.time-line__button-down {
  left: 28px;
  top: 292px;
  transform: rotate(-90deg);

  @include viewport--md {
    display: none;
  }
}

.time-line--mobile {
  display: none;
}


.time-line__navigation-container {
  p {
    display: none;
  }

  .time-line__count-prev {
    @include viewport--md {
      margin-left: 16px;
    }
  }

    .time-line__count-next {
    @include viewport--md {
      margin-right: 16px;
    }
  }

  @include viewport--md {
    display: flex;
    margin: 0 25px 70px;
    justify-content: space-between;
    align-items: center;
    position: relative;



    p {
      display: block;
      margin: 0 17px;

      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      color: $color-tahiti-gold;
    }
  }
}


@include viewport--md {
  .time-line--desc {
    display: none!important;
  }
  .time-line--mobile {
    display: flex;
  }
}
