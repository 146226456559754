@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/raleway-regular.woff2") format("woff2"),
    url("../fonts/raleway-regular.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/raleway-medium.woff2") format("woff2"),
    url("../fonts/raleway-medium.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/raleway-semi-bold.woff2") format("woff2"),
    url("../fonts/raleway-semi-bold.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/raleway-bold.woff2") format("woff2"),
    url("../fonts/raleway-bold.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/raleway-extra-bold.woff2") format("woff2"),
    url("../fonts/raleway-extra-bold.woff") format("woff");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/raleway-black.woff2") format("woff2"),
    url("../fonts/raleway-black.woff") format("woff");
}
