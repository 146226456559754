.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100 * var(--vh, 1vh));
  overflow-x: hidden;

  &--no-scrollbar {
    height: calc(100 * var(--vh, 1vh));
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;

    //display: flex;
    //align-items: center;
    //justify-content: center;
  }

  &.wrapper--active {
    @include viewport--sm {
      .time-line-main {
        padding-top: 120px;
        transition: padding-top $default-transition-settings;
      }

      h1 {
        min-height: 22px;
        padding-right: 20px;
        margin: 8px 0;
        font-weight: 800;
        font-size: 20px;
        line-height: 26px;
      }

      h2 {
        display: block;
      }
      .header {
        box-shadow: 0 4px 10px rgba(94, 94, 94, 0.1);
      }

      .header__wrapper {
        margin-top: 0;
        margin-bottom: 14px;
      }
    }
  }
}

.scroll-lock {
  position: fixed;

  overflow-y: auto;
}

.btn-reset {
  padding: 0;

  background: $color-transparent;
  border: none;
  cursor: pointer;
}

@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}
