.header {
  margin-top: 39px;
  margin-bottom: 32px;

  @include viewport--md {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    width: 100%;
    margin: 0;
    padding-top: 24px;
    padding-bottom: 26px;
    background-color: $color-default-white;
  }

  @include viewport--sm {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  h1 {
    margin: 0 30px 0 0;
    font-weight: bold;
    font-size: 60px;
    line-height: 60px;
    color: $color-tahiti-gold;
    pointer-events: none;
    display: block;

    @include viewport--md {
      margin-right: 0;
      font-weight: 900;
      font-size: 32px;
      line-height: 38px;
      opacity: 1;
      transition: all 0.3s ease-out;
    }
  }
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @include viewport--md {
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 12px;
    transition: margin-top $default-transition-settings,
                margin-bottom $default-transition-settings;
  }
}

.header__link {
  cursor: pointer;

  @include viewport--md {
    display: none;
  }
}

.header__filter {
  display: none;

  @include viewport--md {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: $color-tahiti-gold;

    &--active {
      color: $color-default-white;
    }
  }
}
