.preloader {
  position: fixed;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-top: 78px;
  padding-bottom: 80px;
  background-image: url("../img/bg/bg-start@1x.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  overflow-y: auto;

  &--hidden {
    display: none;
  }

  @include retina {
    background-image: url("../img/bg/bg-start@2x.jpg");
  }

  @media (max-width: 1200px) {
    padding-left: 4.884%;
  }
}

.preloader__container {
  display: flex;
  justify-content: space-between;
  max-width: 934px;
  height: calc(100 * var(--vh, 1vh) - 78px);
  margin: 0 auto;
  padding-left: 56px;
  padding-right: 20px;

  @include viewport--md {
    max-width: none;
    padding: 0;
    flex-direction: column;
    height: calc(100 * var(--vh, 1vh) - 178px);
  }
}

.preloader__col-1 {
  margin-right: 120px;
  margin-bottom: 80px;

  @include viewport--md {
   // min-height: 667px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.preloader a {
  display: block;
  margin-top: 12px;
  margin-bottom: 205px;

  &:last-child {
    margin-bottom: 0;
  }

  @include viewport--md {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.preloader img {
  width: 150px;
  height: 81px;
}

.preloader__button {
  display: inline-block;
  width: max-content;
  margin: 0 0 7px 1px;
  padding: 15px 41px;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  vertical-align: middle;
  background: $color-tahiti-gold;
  color: $color-default-white;
  border: 0;
  border-radius: 45px;
  letter-spacing: 0.05em;
  transition: background $default-transition-settings,
    box-shadow $default-transition-settings;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background: linear-gradient(180deg, rgba($color-default-white, 0.177) 0%, rgba($color-default-white, 0.24) 5.72%, rgba($color-default-white, 0) 73.21%), $color-tahiti-gold;
    box-shadow: 0 10px 15px rgba($color-default-black, 0.1);
  }

  &:active {
    background: $color-tahiti-gold;
    box-shadow: inset 0 0 5px rgba($color-default-black, 0.25);
  }
}

.preloader__col-2 {
  max-width: 551px;
  height: auto;
  padding-bottom: 80px;
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  @include viewport--md {
    position: relative;
    padding-right: 24px;
    overflow: auto;
    margin-bottom: 20px;

    -ms-overflow-style: auto;
    scrollbar-width: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: $color-grey;
    }
  }
}

.preloader h1 {
  margin: 0;
  margin-bottom: 26px;
  font-weight: 900;
  font-size: 50px;
  line-height: 50px;
  color: $color-tahiti-gold;
}

.preloader p {
  margin: 0;
  margin-bottom: 27px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;

  &:last-child {
    margin-bottom: 0;
  }
}

.preloader-before-modal {
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1001;
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  cursor: none;
}

.modal-loader-on {
  .preloader-before-modal {
    display: flex;
  }

  .footer,
  .time-line {
    pointer-events: none;
  }

  .modal--success {
    opacity: 0!important;
  }
}


.preloader {
  @include viewport--md {
    height: calc(100 * var(--vh, 1vh));
    padding: 24px;
    background-image: url("../img/bg/bg-mobile-start@1x.jpg");

    @include retina {
      background-image: url("../img/bg/bg-mobile-start@2x.jpg");
    }
  }
}

.preloader__container {
}

.preloader__col-1 {
}

.preloader a {
}

.preloader img {
  @include viewport--md {
    width: 131px;
    height: 75px;
  }
}

.preloader__button {
  @include viewport--md {
    margin: 0;
    padding: 12px 46px 11px 52px;
    font-size: 20px;
    line-height: 25px;
  }
}

.preloader__col-2 {

}

.preloader h1 {
  @include viewport--md {
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 48px;
  }
}

.preloader p {
  @include viewport--md {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
  }
}


.preloader__button--desc {

}

.preloader__button--mobile {
  display: none!important;
}

@include viewport--md {
  .preloader__button--desc {
    display: none!important;
  }

  .preloader__button--mobile {
    display: block!important;
  }
}
