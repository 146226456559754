.slider {
  position: relative;
  //width: calc(100vw - 155px);
  // min-height: 570px;
  margin-right: 0;
  margin-left: 155px;
  //padding-top: 55px;
  overflow: visible;

  @include viewport--md {
    min-height: auto;
    margin: 0;
    padding: 0 24px 0;
    overflow: hidden;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: -100px;
    background: white;
    width: 100vw;
    right: 100%;
    z-index: 10;
  }
}

.slider__slide {
  display: flex;
  width: auto;

  &:last-of-type {
    min-width: 100%;
  }
  @include viewport--sm {
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
  }
}

.slider__divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25px;
  height: 361px;
  margin-right: 20px;
  text-align: center;


  @include viewport--md {
    display: none;
  }
}

.slider__divider-line {
  display: block;
  width: 1px;
  height: 336px;
  border: 1px dashed $color-grey;
}

.slider__divider span {
  font-size: 10px;
  line-height: 25px;
  color: $color-grey;
  letter-spacing: 0.05em;
}

.slider__card {
  margin-right: 20px;
  overflow: hidden;

  .button--events {
    max-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @include viewport--md {
    margin-bottom: 10px;
  }

  @include viewport--sm {
    margin-right: 0;

    .button--events {
      max-width: none;
      white-space: normal;
    }
  }
}

.slider__card h3 {
  width: 368px;
  margin: 5px 0 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: $color-tahiti-gold;

  @include viewport--md {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 8px;
  }
}

.slider__link {
  display: block;
  outline: none;
}

.slider__link-container {
  max-width: 368px;
  overflow: hidden;

  @include viewport--sm {
    max-width: 100%;
  }
}

.slider__text {
  width: 368px;
  margin: 0;

  @include viewport--md {
    width: 100%;
    max-height: none;
    margin-bottom: 64px;
  }
  @include viewport--sm {
    margin-bottom: 44px;
    width: 100%;
  }
}

.slider__img-container {
  position: relative;
  width: 368px;
  height: 320px;
  margin-bottom: 16px;

  &:hover img {
    transform: scale(1.26);
    filter: grayscale(0%);
  }

  @include viewport--md {
    margin-bottom: 21px;
  }

  @include viewport--sm {
    width: 100%;
  }

  &:hover img {
    @include viewport--md {
      transform: scale(1);
      filter: grayscale(100%);
    }
  }
}

.slider__img-label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 13px;
  z-index: 1;


  &--2,
  &--tmk-group {
    background-color: $color-tahiti-gold;
  }

  &--3,
  &--15,
  &--vtz {
    background-color: $color-mandy;
  }

  &--4,
  &--stz {
    background-color: $color-san-marino;
  }

  &--8,
  &--tmk-artrom {
    background-color: $color-beaver;
  }

  &--9,
  &--tmk-ipsco {
    background-color: $color-scarlet;
  }

  &--7,
  &--tagmet {
    background-color: $color-fruit-salad;
  }

  &--5,
  &--sin-tz {
    background-color: $color-gulf-stream;
  }

  &--10,
  &--ktp {
    background-color: $color-cream-can;
  }

  &--11,
  &--omz {
    background-color: $color-bouquet;
  }

  &--12,
  &--tmk-gipi {
    background-color: $color-wewak;
  }
}

.slider__img-container img {
  border-style: none;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  padding-top: 13px;
  object-fit: cover;
  transition: transform $default-transition-settings,
    filter $default-transition-settings;
  filter: grayscale(100%);
}

.slider .button {
  margin-right: 11px;
  margin-bottom: 11px;
  pointer-events: none;

  @include viewport--md {
    margin-right: 0;
    margin-bottom: 8px;

    &--events {
      margin-right: 4px;
    }
  }
}

.swiper-lazy-preloader {
  display: none!important;
}
