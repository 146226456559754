.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background-color: rgba($color-matterhorn, 0.8);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 100%;
    max-width: 1144px;

    background-color: $color-default-white;
  }

  &__close-btn {
    position: absolute;
    top: 21px;
    right: 30px;
    z-index: 1;
    display: block;
    width: 15px;
    height: 15px;

    &:active,
    &:focus {
      outline: none;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -12px;
      left: -12px;
      padding: 20px;
    }

    svg {
      stroke: $color-silver;
      stroke-width: 3px;
    }

    &:hover svg,
    &:focus svg {
      stroke: $color-tahiti-gold;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

.modal {
  @include viewport--md {
    width: 100%;
    background-color: $color-default-white;
  }

  &__wrapper {
    width: 100%;

    @include viewport--md {
      width: 100%;
      padding: 0;
    }
  }

  &__content {
    @include viewport--md {
      width: 100%;
      padding: 0;
    }
  }

  &__close-btn {
    svg {
      @include viewport--md {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        stroke: $color-default-white;
        stroke-width: 1.5px;
      }
    }

    &:hover svg,
    &:focus svg {
      @include viewport--md {
        stroke: $color-default-white;
      }
    }
  }

  &--fit-content {
    @include viewport--md {
      width: 100%;
    }
  }
}

.modal.modal--filter {
  @include viewport--md {
    transform: translateX(100%);
    visibility: visible;
    opacity: 1;
  }
}

.modal.modal--filter.modal--active {
  @include viewport--md {
    transform: none;
  }
}

.modal__content > .modal__close-btn {
  @include viewport--md {
    display: none;
  }
}

.modal--filter {
  @include viewport--md {
    z-index: 1001;
  }
}
