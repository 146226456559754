.event {
  display: flex;
  width: 1144px;
  height: 594px;

  @media(max-width: 1150px) {
    width: 100%;
  }
}

.event__slider,
.event__slide {
  height: 594px;
  max-width: 752px;
  width: 100%;

  @include viewport--md {
    order: -2;
    width: 100vw;
    max-width: 100%;
    height: 480px;
    margin: 0;
  }

  @include viewport--sm {
    height: 380px;
    min-width: 100vw;
  }
}

.event__slide video,
.event__slide iframe {
  border: none;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.swiper-slide--video {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.playedvideo {
  .swiper-slide--video {
    picture,
    img,
    .playVideo {
      display: none;
    }

    iframe {
      z-index: 10;
    }
  }
}
.videoIframe {
  display: none;
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.videoIframeClose {
  display: none;
  z-index: 1200;
  position: fixed;
  top: 60px;
  right: 60px;
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 50%;
  stroke: $color-tahiti-gold;
}
.playedvideoFixed {
  .videoIframeClose,
  .videoIframe {
    display: block;
  }
}

.playVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  z-index: 7;
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  cursor: pointer;

  &::before {
    content: '';
    z-index: 3;
    display: block;
    margin: 0;
    padding: 0;
    background: #ffffff;
    opacity: .6;
    cursor: pointer;
    outline: 0;
    transition: opacity .3s ease;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  svg {
    width: 26px;
    height: 26px;
    stroke: $color-white;
    z-index: 4;
  }

  &::before ,
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover{
    svg {
      stroke: $color-tahiti-gold;
    }

    &::before {
      opacity: 1;
    }
  }
}

.event__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event__description {
  position: relative;
  height: 594px;
  padding: 73px 42px;
  border: 1px solid $color-tahiti-gold;
  border-left: none;

  width: 100%;
  max-width: 392px;

  &::before {
    content: "";
    position: absolute;
    top: 86px;
    left: 0;
    z-index: 1;
    display: block;
    width: 34px;
    height: 3px;
    background-color: $color-tahiti-gold;
  }
}

.event__text-container-wrap {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 20px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }

  &::before {
    top: 0;
    background: linear-gradient(180deg, $color-default-white 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(0deg, $color-default-white 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.event__text-container {
  position: relative;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.05em;
  overflow: auto;
  padding: 25px 15px 25px 2px;
  margin: -25px -15px 35px 0;
  height: 330px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
  }
}

.event__description h3 {
  margin: 0;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  color: $color-tahiti-gold;
}

.event__description p {
  margin: 0;

  & + p {
    margin-top: 1em;
  }
}

.event__description .button {
  margin-right: 16px;
  padding: 2px 9px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: normal;
  pointer-events: none;

  .enterprises-filter__button {
    position: relative;
    padding-left: 20px;
    padding-right: 8px;
    font-weight: bold;
    background-color: $color-tahiti-gold;
    color: $color-white;

    &::before {
      content: "";
      position: absolute;
      top: 9px;
      left: 9px;
      display: block;
      width: 5px;
      height: 5px;
      background-color: $color-white;
      border-radius: 50%;
    }
  }

  &--tmk-group {
    background-color: $color-tahiti-gold;
  }

  &--stz {
    background-color: $color-san-marino;
  }

  &--vtz {
    background-color: $color-mandy;
  }

  &--tmk-artrom {
    background-color: $color-beaver;
  }

  &--tmk-ipsco {
    background-color: $color-scarlet;
  }

  &--tagmet {
    background-color: $color-fruit-salad;
  }

  &--sin-tz {
    background-color: $color-gulf-stream;
  }

  &--ktp {
    background-color: $color-cream-can;
  }

  &--omz {
    background-color: $color-bouquet;
  }

  &--tmk-gipi {
    background-color: $color-wewak;
  }
}

.event__pagination.swiper-pagination {
  bottom: 42px;
  position: absolute;
}

.event__pagination-item,
.event__pagination-current-item {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 4px;
  vertical-align: middle;
  border: 2px solid rgba($color-default-white, 0.8);
  border-radius: 50%;
  cursor: pointer;
}

.event__pagination-current-item {
  background-color: $color-default-white;
  border-color: $color-default-white;
}

.event__button-next,
.event__button-prev {
  position: absolute;
  top: calc(50% - 13px);
  left: 33px;
  z-index: 1;
  display: block;
  margin: 0;
  padding: 0;
  background-color: rgba($color-default-white, 0.6);
  width: 33px;
  height: 33px;
  border: 0 solid $color-default-white;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: background-color $default-transition-settings,
    border-color $default-transition-settings;

  &:hover {
    background-color: rgba($color-default-white, 0.85);

    svg {
      stroke: $color-tahiti-gold;
      stroke-opacity: 1;
      transition:  stroke $default-transition-settings,
        stroke-opacity $default-transition-settings;
    }
  }
}

.event__button-next {
  right: 33px;
  left: auto;
  transform: rotate(180deg);
}

.event__button-next svg,
.event__button-prev svg {
  stroke: $color-default-white;
  fill: none;
  stroke-opacity: 0.7;
}

.event__button-prev.swiper-button-disabled,
.event__button-next.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.3;
}

.event__description .modal__close-btn {
  display: none;
}


.event {
  @include viewport--md {
    flex-direction: column;
    width: 100%;
    min-width: 310px;
    height: auto;
  }
}

.event__description {
  @include viewport--md {
    display: contents;
    height: auto;
    border: none;
    padding: 104px 0 73px 0;
    padding-top: 0;

    &::before {
      display: none;
    }
  }
}

.event__text-container {
  @include viewport--md {
    display: contents;
    height: 179px;
    padding-left: 0;
    font-size: 16px;
    line-height: 24px;
    overflow: visible;
  }
}

.event__header {
  @include viewport--md {

    z-index: 2;
    order: -3;
  }
}

.event__description h3 {
  @include viewport--md {
    width: 100%;
    margin-bottom: 0;
    padding: 32px 87px 31px 24px;
    color: $color-default-white;
    box-shadow: 0 4px 10px rgba(94, 94, 94, 0.1);
    letter-spacing: normal;
  }
}

.event__text {
  @include viewport--md {
    width: 100%;
    padding: 0 24px;
    background-color: $color-default-white;
  }
}

.event__tag-container {
  @include viewport--md {
    order: -1;
    width: 100%;
    padding: 16px 24px;
    background-color: $color-default-white;
  }
}

.event__description .button {
  @include viewport--md {
    padding: 3px 18px 3px 14px;
    margin-right: 4px;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    background-color: $color-default-white;

    &--enterprises {
      padding: 3px 21px 3px 19px;
    }
  }

  .enterprises-filter__button {
    &::before {
      @include viewport--md {
        display: none;
      }
    }
  }

  &--tmk-group {
    @include viewport--md {
      color: $color-tahiti-gold;
    }
  }

  &--stz {
    @include viewport--md {
      color: $color-san-marino;
    }
  }

  &--vtz {
    @include viewport--md {
      color: $color-mandy;
    }
  }

  &--tmk-artrom {
    @include viewport--md {
      color: $color-beaver;
    }
  }

  &--tmk-ipsco {
    @include viewport--md {
      color: $color-scarlet;
    }
  }

  &--tagmet {
    @include viewport--md {
      color: $color-fruit-salad;
    }
  }

  &--sin-tz {
    @include viewport--md {
      color: $color-gulf-stream;
    }
  }

  &--ktp {
    @include viewport--md {
      color: $color-cream-can;
    }
  }

  &--omz {
    @include viewport--md {
      color: $color-bouquet;
    }
  }

  &--tmk-gipi {
    @include viewport--md {
      color: $color-wewak;
    }
  }
}

.event__pagination.swiper-pagination {
  @include viewport--md {
    bottom: 48px;
  }
}

.event__pagination-item,
.event__pagination-current-item {
  @include viewport--md {
    position: relative;
    width: 12px;
    height: 12px;
    margin-top: 28px;
    margin-right: 28px;

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: -16px;
      left: -16px;
      display: block;
      width: 40px;
      height: 40px;
    }
  }
}

.event__button-next,
.event__button-prev {
  @include viewport--md {
    display: none;
  }
}

.event__description .modal__close-btn {
  @include viewport--md {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;
    display: block;
    width: 40px;
    height: 40px;
    border: 1.5px solid $color-default-white;
    border-radius: 50%;
  }
}
