.button {
  display: inline-block;
  margin: 0 16px 16px 0;
  padding: 3px 9px;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid $color-gainsboro-2;
  border-radius: 20px;
  transition: color $default-transition-settings,
    background-color $default-transition-settings,
    border-color $default-transition-settings;
  outline: none;
  cursor: pointer;

  &::before {
    transition: background-color $default-transition-settings;
  }

  &--disabled {
    pointer-events: none;
    cursor: text;
  }

  &--timeline-enterprises {

    &:not(.button--events):hover {
      box-shadow: 0 0 10px rgba($color-default-black, 0.15);
    }
  }

  &--events {
    margin-right: 11px;
    color: $color-grey;
    border-color: $color-grey;
    text-align: left;

    &:empty {
      display: none;
    }
  }

  &--2,
  &--tmk-group {
    color: $color-tahiti-gold;
    border-color: $color-tahiti-gold;
  }

  &--15,
  &--3,
  &--vtz {
    color: $color-mandy;
    border-color: $color-mandy;
  }

  &--4,
  &--stz {
    color: $color-san-marino;
    border-color: $color-san-marino;
  }

  &--8,
  &--tmk-artrom {
    color: $color-beaver;
    border-color: $color-beaver;
  }

  &--9,
  &--tmk-ipsco {
    color: $color-scarlet;
    border-color: $color-scarlet;
  }

  &--7,
  &--tagmet {
    color: $color-fruit-salad;
    border-color: $color-fruit-salad;
  }

  &--5,
  &--sin-tz {
    color: $color-gulf-stream;
    border-color: $color-gulf-stream;
  }

  &--10,
  &--ktp {
    color: $color-cream-can;
    border-color: $color-cream-can;
  }

  &--11,
  &--omz {
    color: $color-bouquet;
    border-color: $color-bouquet;
  }

  &--12,
  &--tmk-gipi {
    color: $color-wewak;
    border-color: $color-wewak;
  }
}


.button {
  @include viewport--md {
    font-size: 15px;
    line-height: 26px;
    letter-spacing: normal;
  }

  @include viewport--sm {
    font-size: 13px;
    line-height: 22px;
  }

  &--timeline-enterprises {
    @include viewport--md {
      padding: 2px 19px;
      border-radius: 25.6px;
    }
  }

  &--events {
    @include viewport--md {
      margin-right: 4px;
      padding: 2px 14px;
      border-color: $color-gainsboro-2;

      &.button--active {
        color: $color-white;
        background-color: $color-grey;
        border-color: $color-grey;
      }
    }
  }

  &--2,
  &--tmk-group {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-tahiti-gold;
        border-color: $color-tahiti-gold;
      }
    }
  }

  &--15,
  &--3,
  &--vtz {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-mandy;
        border-color: $color-mandy;
      }
    }
  }

  &--4,
  &--stz {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-san-marino;
        border-color: $color-san-marino;
      }
    }
  }

  &--8,
  &--tmk-artrom {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-beaver;
        border-color: $color-beaver;
      }
    }
  }

  &--9,
  &--tmk-ipsco {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-scarlet;
        border-color: $color-scarlet;
      }
    }
  }

  &--7,
  &--tagmet {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-fruit-salad;
        border-color: $color-fruit-salad;
      }
    }
  }

  &--5,
  &--sin-tz {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-gulf-stream;
        border-color: $color-gulf-stream;
      }
    }
  }

  &--10,
  &--ktp {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-cream-can;
        border-color: $color-cream-can;
      }
    }
  }

  &--11,
  &--omz {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-bouquet;
        border-color: $color-bouquet;
      }
    }
  }

  &--12,
  &--tmk-gipi {
    @include viewport--md {
      &.button--active {
        color: $color-white;
        background-color: $color-wewak;
        border-color: $color-wewak;
      }
    }
  }
}
