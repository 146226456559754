*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  min-width: 1024px;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  font-family: $font-family--raleway;
  color: $color-default-black;
  background-color: $color-default-white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include viewport--md {
    min-width: 310px;
  }
}

a {
  color: $color-default-black;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

.IE11 {
  display: none;

  &--show {
    position: absolute;
    top: 30%;
    left: 60px;
    display: block;
    width: calc(100vw - 120px);
    margin: 0;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 90px;
    line-height: 100%;
    color: $color-tahiti-gold;

    @mixin viewport--sm {
      top: 10%;
      left: 10px;
      font-size: 60px;
      line-height: 100%;
    }
  }
}

.resize-text {
  display: none;

  @include viewport--md {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10100;
    display: block;
    width: 100vw;
    height: calc(100 * var(--vh, 1vh));
    margin: 0;
    padding: 50px;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 100%;
    background-color: $color-default-white;
    color: $color-tahiti-gold;
    overflow: auto;
  }
}

// chrome autofill background removal
// Если на проекте у инпутов используются разные цвета фонов\текста -
// удалите это из глобала и используйте локально с нужными цветами
// rgba не подойдет, сконвертируйте цвет в hex без прозрачности
// если в стилях уже используется box-shadow есть другое решение -
// задать к списку транзишенов `background-color 10000000s ease-out`
input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px $color-default-white;

  -webkit-text-fill-color: $color-default-black;
}

// firefox placeholder \ invalid fix + ios bdrs
input,
textarea {
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// ie11 X removal
input {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

// chrome search X removal
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

// input[number] arrows removal
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

// ios button \ inputs reset
select,
textarea,
input:matches([type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]) {
  appearance: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
}
