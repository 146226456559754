.pagination {
  z-index: 21;
  padding: 0;
  text-align: start;
  list-style: none;
  margin: 0 -7px;

  @include viewport--md {
    z-index: 2;
    display: flex;
    padding: 0 25px;
    width: calc(100% + 50px);
    margin: 0 -25px;
    overflow-x: auto;
    scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
  }
}

.pagination__item {
  position: relative;
  display: inline-block;
  min-width: 51px;
  height: 23px;
  padding: 0 3px 2px 3px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
  color: $color-grey;
  border: 0;
  margin: 0;
  transition: color $default-transition-settings,
    background-color $default-transition-settings;
  letter-spacing: -0.02em;
  outline: none;
  cursor: pointer;
  z-index: 10;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: -1;
    background-color: $color-tahiti-gold;
    border-radius: 11px;
    opacity: 0;
    transition: opacity .3s ease;
  }

  &:active,
  &:focus {
    outline: none;
  }


  &:not(:last-child) {
    margin: 0 14px 0 0;

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      right: -8px;
      width: 3px;
      height: 2px;
      background-color: $color-tahiti-gold;
      border-radius: 50%;
    }
  }

  &:hover:not(.pagination__current-item),
  &:focus:not(.pagination__current-item) {
    color: $color-matterhorn;
  }

  @include viewport--md {
    padding: 0 8px 2px 8px;

    &:last-of-type {
      padding-right: 35px;
      width: 75px;
    }
  }

}

.pagination__current-item {
  color: $color-default-white;
  pointer-events: none;

  &::after {
    opacity: 1;
  }
}
