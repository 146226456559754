.popup {
  position: relative;
  width: 100%;

  .popup-slider__button-next,
  .popup-slider__button-prev {
    outline: none;
  }

  &--2 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-tahiti-gold;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-tahiti-gold;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-tahiti-gold;
      }
    }

    .event__description {
      border-color: $color-tahiti-gold;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-tahiti-gold;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--4 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-san-marino;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-san-marino;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-san-marino;
      }
    }

    .event__description {
      border-color: $color-san-marino;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-san-marino;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--15 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-mandy;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-mandy;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-mandy;
      }
    }

    .event__description {
      border-color: $color-mandy;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-mandy;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--8 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-beaver;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-beaver;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-beaver;
      }
    }

    .event__description {
      border-color: $color-beaver;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-beaver;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--9 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-scarlet;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-scarlet;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-scarlet;
      }
    }

    .event__description {
      border-color: $color-scarlet;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-scarlet;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--7 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-fruit-salad;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-fruit-salad;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-fruit-salad;
      }
    }

    .event__description {
      border-color: $color-fruit-salad;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-fruit-salad;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--5 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-gulf-stream;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-gulf-stream;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-gulf-stream;
      }
    }

    .event__description {
      border-color: $color-gulf-stream;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-gulf-stream;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--10 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-cream-can;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-cream-can;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-cream-can;
      }
    }

    .event__description {
      border-color: $color-cream-can;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-cream-can;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--11 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-bouquet;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-bouquet;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-bouquet;
      }
    }

    .event__description {
      border-color: $color-bouquet;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-bouquet;
        transition: background-color $default-transition-settings;
      }
    }
  }

  &--12 {
    .popup-slider__button-next,
    .popup-slider__button-prev {
      border-color: $color-wewak;
      transition: border-color $default-transition-settings,
        background-color $default-transition-settings;

      svg {
        stroke: $color-wewak;
        transition: stroke $default-transition-settings;
      }

      &:active {
        background-color: $color-wewak;
      }
    }

    .event__description {
      border-color: $color-wewak;
      transition: border-color $default-transition-settings;

      &::before {
        background-color: $color-wewak;
        transition: background-color $default-transition-settings;
      }
    }
  }
}


.popup {
  @include viewport--md {
    height: calc(100 * var(--vh, 1vh));
  }

  &--2 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-tahiti-gold;
      }
    }
  }

  &--4 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-san-marino;
      }
    }
  }

  &--15 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-mandy;
      }
    }
  }

  &--8 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-beaver;
      }
    }
  }

  &--9 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-scarlet;
      }
    }
  }

  &--7 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-fruit-salad;
      }
    }
  }

  &--5 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-gulf-stream;
      }
    }
  }

  &--10 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-cream-can;
      }
    }
  }

  &--11 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-bouquet;
      }
    }
  }

  &--12 {
    .event__description {
      &::before {
        @include viewport--md {
          display: none;
        }
      }
    }

    h3 {
      @include viewport--md {
        background-color: $color-wewak;
      }
    }
  }
}
