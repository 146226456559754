.filter {
  display: none;
}

.filter {
  @include viewport--md {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    display: block;
    width: 100%;
    min-width: 310px;
    min-height: calc(100 * var(--vh, 1vh));
    padding: 51px 24px;
    background-color: $color-default-white;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    overflow-y: auto;

    transition: opacity $modal-transition-settings,
    transform $modal-transition-settings,
    visibility $modal-transition-settings;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &--active {
      visibility: visible;
      opacity: 1;
      transform: none;
    }
  }
}

.filter h2 {
  @include viewport--md {
    margin: 0;
    margin-bottom: 40px;
    padding-right: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: $color-tahiti-gold;
  }
}

.filter__close-btn {
  @include viewport--md {
    position: absolute;
    top: 40px;
    right: 24px;
    z-index: 1002;
    display: block;
    width: 40px;
    height: 40px;
    border: 1.5px solid $color-tahiti-gold;
    border-radius: 50%;
    outline: none;

    &::before {
      display: none;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-top: 3px;
      stroke: $color-tahiti-gold;
      stroke-width: 1.5px;
    }
  }
}

.filter__enterprises {
  @include viewport--md {
    margin-top: -4px;
  }
}

.filter__enterprises h3,
.filter__events h3 {
  @include viewport--md {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;

    color: $color-zambezi;
  }
}

.filter__events h3 {
  @include viewport--md {
    margin-top: 17px;
  }
}

.filter__enterprises-list,
.filter__events-list {
  @include viewport--md {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.filter__enterprises-list span:nth-child(2n + 2) {
  @include viewport--md {
    display: none;
  }
}

.filter__events-list > li:nth-child(4n + 1),
.filter__events-list > li:nth-child(4n + 4) {
  @include viewport--md {
    order: -1;
  }
}

.filter__enterprises-list .button {
  @include viewport--md {
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 2px 19px 2px 20px;
  }
}

.filter__events-list .button {
  @include viewport--md {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
