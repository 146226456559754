.popup-slider,
.popup-slider__slide {
  min-height: 594px;
  width: 100%;

  @include viewport--md {
    width: 100vw;
    min-height: 1px;
  }
  @include viewport--sm {
    padding-bottom: 60px;
  }
}

.popup-slider__events {
  position: absolute;
  right: 113px;
  bottom: 11px;
  max-width: 81px;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: end;
  color: $color-grey;

  @include viewport--md {
    display: none;
  }
}

.popup-slider__button-prev,
.popup-slider__button-next {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  margin: 0;
  padding: 0;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid $color-tahiti-gold;
  cursor: pointer;

  @include viewport--md {
    display: none;
  }

  &:hover svg {
    transform: scale(1.3);
    transition: transform $default-transition-settings;
  }

  &:active {
    svg {
      stroke: $color-default-white;
    }
  }
}

.popup-slider__button-prev {
  right: 49px;
  transform: rotate(180deg);
}

.popup-slider__button-prev--disabled,
.popup-slider__button-next--disabled {
  pointer-events: none;
  opacity: 0.4;
}

.popup-slider__button-prev svg,
.popup-slider__button-next svg {
  fill: transparent;
  stroke: $color-tahiti-gold;
}
