.footer {
  min-height: 115px;
  margin-top: 36px;
  padding-bottom: 30px;
  position: relative;
  z-index: 10;

  @include viewport--md {
    min-height: 80px;
    margin-top: 0;
    padding-top: 24px;
    padding-bottom: 32px;
    background-color: $color-default-white;
    box-shadow: 0 -4px 10px 0 rgba(94, 94, 94, 0.1);
    overflow: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      justify-content: space-between;
    }
  }

  .ya-share2 {
    &__title {
      display: none;
    }
  }
}

.footer .container {
  display: flex;

  @include viewport--md {
    align-items: center;
  }
}

.footer__social-text {
  display: none;

  @include viewport--md {
    z-index: 1;
    display: flex;
    min-width: 160px;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $color-silver;
  }
}

.footer__social {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 155px;
  margin: 0;
  padding: 0;
  list-style: none;

  @include viewport--md {
    margin-left: auto;
    flex-direction: row;
    margin-right: -8px;
    width: auto;
    flex-wrap: nowrap;
  }
}

.footer__social-item {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 8px;

  @include viewport--md {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer__social-link {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  outline: none;
  margin: 5px 0;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    top: -50%;
    left: -50%;
  }

  &:hover svg {
    fill: $color-tahiti-gold;
  }

  &:active svg {
    opacity: 0.7;
  }

  @include viewport--md {
    width: 24px;
    height: 24px;
    margin: 0 8px;

    &::before {
      top: -8px;
      left: -8px;
    }
  }

  svg {
    fill: $color-silver;
    transition: fill $default-transition-settings,
    opacity $default-transition-settings;

    @include viewport--md {
      width: 24px;
      height: 24px;
    }
  }
}


.footer__filter {
  position: relative;
  width: 670px;
  min-height: 160px;
  padding-left: 64px;
  border-left: 1px solid $color-grey;

  &--events {
    width: 433px;
    margin-left: auto;
  }

  @include viewport--md {
    display: none;
  }
}

.footer__filter h2 {
  position: absolute;
  top: 38px;
  left: -31px;
  display: inline-block;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
  text-transform: uppercase;
  color: $color-zambezi;
  transform: rotate(-90deg);
}

.footer__filter--events h2 {
  top: 23px;
  left: -17px;
}

.footer__filter p {
  margin: 0 0 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $color-silver;
}

.footer__filter ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}


.footer__filter li {
  margin-right: 21px;
  margin-bottom: 19px;
}

.enterprises-filter__item {
  &--2,
  &--3,
  &--4,
  &--5,
  &--8,
  &--9 {
    order: -1;
  }
}

.footer__filter .button {
  position: relative;
  outline: none;

  &::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 9px;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }


  &.enterprises-filter__button {
    margin-right: 0;
    margin-bottom: 0;
    padding: 3px 9px 3px 19px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: normal;
    border-color: $color-gainsboro-2;

    & span:nth-child(2n + 2) {
      display: none;
    }

    &:hover {
      @media(min-width: 1200px) {
        span:nth-child(2n + 1) {
          display: none;
        }

        span:nth-child(2n + 2) {
          display: block;
        }
      }
    }
  }

  &--2,
  &--tmk-group {
    &::before {
      background-color: $color-tahiti-gold;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-tahiti-gold;
      border-color: $color-tahiti-gold;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--4,
  &--stz {
    &::before {
      background-color: $color-san-marino;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-san-marino;
      border-color: $color-san-marino;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--3,
  &--15,
  &--vtz {
    &::before {
      background-color: $color-mandy;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-mandy;
      border-color: $color-mandy;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--8,
  &--tmk-artrom {
    &::before {
      background-color: $color-beaver;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-beaver;
      border-color: $color-beaver;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--9,
  &--tmk-ipsco {
    &::before {
      background-color: $color-scarlet;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-scarlet;
      border-color: $color-scarlet;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--7,
  &--tagmet {
    &::before {
      background-color: $color-fruit-salad;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-fruit-salad;
      border-color: $color-fruit-salad;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--5,
  &--sin-tz {
    &::before {
      background-color: $color-gulf-stream;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-gulf-stream;
      border-color: $color-gulf-stream;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--10,
  &--ktp {
    &::before {
      background-color: $color-cream-can;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-cream-can;
      border-color: $color-cream-can;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--11,
  &--omz {
    &::before {
      background-color: $color-bouquet;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-bouquet;
      border-color: $color-bouquet;

      &::before {
        background-color: $color-white;
      }
    }
  }

  &--12,
  &--tmk-gipi {
    &::before {
      background-color: $color-wewak;
    }

    &.button--active {
      color: $color-white;
      background-color: $color-wewak;
      border-color: $color-wewak;

      &::before {
        background-color: $color-white;
      }
    }
  }

  // &--tmk-resita {
  //   &::before {
  //     background-color: $color-martini;
  //   }

  //   &.button--active {
  //     color: $color-white;
  //     background-color: $color-martini;
  //     border-color: $color-martini;

  //     &::before {
  //       background-color: $color-white;
  //     }
  //   }
  // }
}

.footer__filter--events .button {
  &--events {
    margin-right: 0;
    margin-bottom: 0;
    padding: 2px 9px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: normal;
    text-align: left;
    white-space: nowrap;
  }

  &--active {
    color: $color-white;
    background-color: $color-grey;
    border-color: $color-grey;
  }
}
